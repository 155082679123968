import { useContext } from 'react';
import { DigtectiveContext } from '../DigtectiveContext';

export function useDigtectiveContext() {
  const context = useContext(DigtectiveContext);
  if (!context)
    throw new Error(
      'useDigtectiveContext must be used inside DigtectiveContextProvider'
    );
  return context;
}
